/**
 * critical scripts only - anything not required for first render should be placed in deferred-scripts.js
 * rememeber to use keyboard controls where possible for accessibility
 */

/**
 * TABLE OF CONTENTS
 * ---
 * PICTUREFILL
 * LINKS
 * IFRAME ASPECT RATIO
 * DROPDOWN MENU
 * MOBILE MENU OVERLAY
 * ACCORDION
 * RESPONSIVE TABLE
 * LOGO CAROUSEL
 */

$(document).ready(function() {

	/**
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 * PICTUREFILL
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 */

	document.createElement("picture");

	/**
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 * LINKS
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 */
		
	// links ending with '.pdf' open in a new tab
	$('a[href$=".pdf"]').attr('target','_blank');

	// force external links that start with https and https and do not have the site base url to open in a new tab
	var baseUrl = document.location.origin;
	$('a[href^="http://"], a[href^="https://"]').not('a[href^="'+baseUrl+'"]').attr('target','_blank');

	// add 'noopener noreferrer' to any link with target="_blank"
	$('a[target="_blank"]').attr('rel', 'noopener noreferrer');

	/**
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 * IFRAME ASPECT RATIO
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 */

	$('.l-page-content iframe').each(function() {
		
		// wrap iframe selector in iframe wrapper class
		$(this).wrap('<div class="c-iframe-wrapper"></div>');

	});

	/**
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 * DROPDOWN MENU
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 */

	$('.js-dropdown-menu-control').on('click', function(e) {
		
		// remove active class from sibling links
		$(this).closest('.c-primary-nav__item').siblings().find('.js-dropdown-menu-control').removeClass('c-dropdown-menu__parent-link--active');

		// toggle active link class
		$(this).toggleClass('c-dropdown-menu__parent-link--active');
		
		// remove open class from all sibling dropdown menus
		$(this).closest('.c-primary-nav__item').siblings().find('.js-dropdown-menu').removeClass('c-dropdown-menu--open');

		// toggle open class
		$(this).next('.js-dropdown-menu').toggleClass('c-dropdown-menu--open');
		
		// change aria hidden of all sibling dropdown menus to true
		$(this).closest('.c-primary-nav__item').siblings().find('.js-dropdown-menu').attr('aria-hidden', 'true');
		
		// change aria hidden to false
		$(this).next('.js-dropdown-menu').attr('aria-hidden', 'false');
		
		// change aria hidden to true when menu not visible
		if( !$(this).next('.js-dropdown-menu').hasClass('c-dropdown-menu--open') ) {
			
			$(this).next('.js-dropdown-menu').attr('aria-hidden', 'true');
			
		}
		
		// stop default action of link
		// exclude dropdown menu from click event that will remove active and open classes, see below
		e.preventDefault();
		e.stopPropagation();

		// exclude dropdown menu from click event that will remove active and open classes, see below
		$('.js-dropdown-menu').on('click', function(e) {
		  
		  e.stopPropagation(); 
			
		});
		
		// caal closeDesktopDropdownMenu function on clicking on body when dropdown is open
		$('body').on('click', function() {

			closeDesktopDropdownMenu();
		  
		});

	});

	// remove active and open classes from all dropdown menus when clicking on the body
	// change aria hidden to true when clicking outside dropdown menu
	function closeDesktopDropdownMenu() {

		$('.js-dropdown-menu-control').removeClass('c-dropdown-menu__parent-link--active');
			
		$('.c-primary-nav__link').removeClass('c-dropdown-menu__parent-link--active');
		
		$('.js-dropdown-menu').removeClass('c-dropdown-menu--open');
		
		$('.js-dropdown-menu').attr('aria-hidden', 'true');

	}

	// detect when nav link is focused on using the tab key
	$(window).keyup(function(e) {
	    var code = (e.keyCode ? e.keyCode : e.which);
	    if (code == 9 && $('.c-primary-nav__link:focus').length) {

			// remove active class from sibling links
			$(e.target).closest('.c-primary-nav__item').siblings().find('.js-dropdown-menu-control').removeClass('c-dropdown-menu__parent-link--active');

			$(e.target).closest('.c-primary-nav__item').siblings().find('.c-primary-nav__link').removeClass('c-dropdown-menu__parent-link--active');

			// toggle active link class
			$(e.target).addClass('c-dropdown-menu__parent-link--active');
	
			// remove open class from all sibling dropdown menus
			$(e.target).closest('.c-primary-nav__item').siblings().find('.js-dropdown-menu').removeClass('c-dropdown-menu--open');

			// toggle open class
			$(e.target).next('.js-dropdown-menu').toggleClass('c-dropdown-menu--open');
		
		}
	});

	// remove active class from primary nav links when focused out of nav
	$('.c-primary-nav__item:last-child').on('focusout', function() {
		
		$('.c-primary-nav__link').removeClass('c-dropdown-menu__parent-link--active');
		
	});

	/**
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 * MOBILE MENU OVERLAY
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 */

	$('.js-open-mobile-nav-overlay').on('click', function(e) {

		// add open class
		$('.js-mobile-nav-overlay').addClass('c-mobile-nav-overlay--open');

		// remove and add state classes
		$('.c-mobile-nav-overlay-open-control').removeClass('c-mobile-nav-overlay-open-control--active').addClass('c-mobile-nav-overlay-open-control--inactive');
		$('.c-mobile-nav-overlay-close-control').removeClass('c-mobile-nav-overlay-close-control--inactive').addClass('c-mobile-nav-overlay-close-control--active');

		// add scroll lock class to body
		$('body').addClass('scroll-lock');

		e.preventDefault();
		e.stopPropagation();

	});

	// call closeMobileOverlay function
	$('.js-close-mobile-nav-overlay').on('click', function(e) {

		closeMobileOverlay(e);

	});

	// remove open class, blur mobile search form, and remove scroll lock
	function closeMobileOverlay(e) {

		$('.js-mobile-nav-overlay').removeClass('c-mobile-nav-overlay--open');

		// remove and add state classes
		$('.c-mobile-nav-overlay-open-control').removeClass('c-mobile-nav-overlay-open-control--inactive').addClass('c-mobile-nav-overlay-open-control--active');
		$('.c-mobile-nav-overlay-close-control').removeClass('c-mobile-nav-overlay-close-control--active').addClass('c-mobile-nav-overlay-close-control--inactive');

		$('body').removeClass('scroll-lock');

		// close any open accordions
		setTimeout(function () {
			$('.js-accordion-control').removeClass('js-accordion-control-active');
			$('.js-accordion-content').slideUp();
			$('.js-mobile-nav-overlay').scrollTop(0);
		}, 300);

		e.preventDefault();
		e.stopPropagation();

	}

	/**
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 * ACCORDION
	 * --------------------------------------------------------------------------------------------------------------------
	 * --------------------------------------------------------------------------------------------------------------------
	 */

	$('.js-accordion-control').on('click', function(e) {

		// note - uncomment next 3 lines to only open one accoridon item at a time
		$(this).closest('.js-accordion-container').siblings().find('.js-accordion-content').slideUp();
		$(this).closest('.js-accordion-container').siblings().find('.js-accordion-control').removeClass('js-accordion-control-active');
		$(this).closest('.js-accordion-container').siblings().find('.js-accordion-control').attr('aria-expanded', 'false');

		// toggle accordion content
		$(this).next('.js-accordion-content').slideToggle();
		
	  // toggle aria expanded value
	  $(this).attr('aria-expanded', $(this).attr('aria-expanded')=='false' ? 'true' : 'false');
		
		// toggle active class
		$(this).toggleClass('js-accordion-control-active');
		
		if( $(this).attr('aria-expanded') === 'true' ) {
			
			// note - uncomment next line to only open one accoridon item at a time
			$(this).closest('.js-accordion-container').siblings().find('.js-accordion-content').attr('aria-hidden', 'true');
			
			// if open change aria hidden value to false
			$(this).next('.js-accordion-content').attr('aria-hidden', 'false');
			
		}
		
		else {
			
			// if closed change aria hidden value to true
			$(this).next('.js-accordion-content').attr('aria-hidden', 'true');
			
		}
			
		e.preventDefault();
		e.stopPropagation();

	});

	/**
 * --------------------------------------------------------------------------------------------------------------------
 * --------------------------------------------------------------------------------------------------------------------
 * RESPONSIVE TABLE
 * --------------------------------------------------------------------------------------------------------------------
 * --------------------------------------------------------------------------------------------------------------------
 */

	// check if table exists
	function addMobileAccessibility() {

	if ($('table').length > 0) {
  
	  // each table
	  $('table').each(function () {
  
		// empty array for storing table heading content
		var tableHeadingArray = [];
  
		// each table heading
		$(this).find('thead tr th').each(function () {
  
		  // push text string from each table heading to array
		  tableHeadingArray.push($(this).text());
  
		});
  
		// loop for as many items as are in the array
		for (var i = 0; i < tableHeadingArray.length; i++) {
  
		  // add aria label attribute with table heading array values to tds wrapping td content in span
		  $(this).find('td:nth-child(' + (i + 1) + ')').attr({
			'aria-label': tableHeadingArray[i],
			'scope': 'row'
		  }).wrapInner('<span class="c-td__span"></span>');
  
		}
  
	  });
  
	}
  
  }
  
  function removeMobileAccessibility() {
  
	// remove span when resizing larger than breakpoint
	$('table td .c-td__span').contents().unwrap();
  
	// remove aria lable and scope attributes - not needed in desktop layout
	$('table td').removeAttr('aria-label').removeAttr('scope');
  
  }
  
  (function (window, document, undefined) {
	'use strict';
  
	// initialize the media query
	var mediaQuery = window.matchMedia('(min-width: 576px)');
  
	// add a listen event
	mediaQuery.addListener(responsiveTable);
  
	// function to do something with the media query
	function responsiveTable(mediaQuery) {
  
	  if (mediaQuery.matches) {
		removeMobileAccessibility();
	  }
  
	  else {
		addMobileAccessibility();
	  }
  
	}
  
	// on load
	responsiveTable(mediaQuery);
  
	})(window, document);
	
/**
* --------------------------------------------------------------------------------------------------------------------
* --------------------------------------------------------------------------------------------------------------------
* LOGO CAROUSEL
* --------------------------------------------------------------------------------------------------------------------
* --------------------------------------------------------------------------------------------------------------------
*/

// check if carousel exists first
if ($('.js-carousel-wrapper').length > 0) {

		$('.js-carousel-wrapper').each(function () {

			$(this).on('init', function (slick) {
				$(this).removeClass('c-carousel-wrapper--loading');
			});

			// initialize slick carousel
			$(this).find('#js-carousel-client-logos').slick({
				dots: true,
				appendDots: $(this).closest('.js-carousel-wrapper').find('.js-carousel-dots'),
				infinite: true,
				slidesToShow: 5,
				slidesToScroll: 5,
				autoplaySpeed: 1500,
				autoplay: true,
				arrows: false,
				swipeToSlide: true,
				pauseOnFocus: false,
				responsive: [

					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 4,
							slidesToScroll: 4
						}
					},

					{
						breakpoint: 992,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 576,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					}

				]
			});

		});

	}
	  
});